import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  ReferenceArrayInput,
  SelectArrayInput,
  required,
  AutocompleteInput,
} from "react-admin";
import { useDepartments } from "../../hooks/assessments.questions/useDepartments";
import OccupationEditorToolbar from "./OccupationEditorToolbar";
import { useOccupationsOnetCodes } from "../../hooks/occupations/useOccupationsOnetCodes";
import { Typography } from "@mui/material";

export const OccupationCreate = (props) => {
  const departments = useDepartments();
  const { onetCodes, isOnetLoading } = useOccupationsOnetCodes();

  return (
    <Create {...props} redirect="list">
      <SimpleForm toolbar={<OccupationEditorToolbar />}>
        <TextInput source="name" validate={[required()]} />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceArrayInput
              filter={{provider: `THRIVE`}}
              label="Recommended assessments"
              source="recommendedAssessmentIds"
              reference="assessments"
              perPage={100}
              {...rest}
            >
              <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
        <SelectInput
          source="normType"
          defaultValue="STANDARD"
          choices={[
            { id: "VERY_EASY", name: "VERY_EASY" },
            { id: "EASY", name: "EASY" },
            { id: "STANDARD", name: "STANDARD" },
            { id: "HARD", name: "HARD" },
          ]}
        />
        <SelectInput
          source="department"
          choices={departments}
          optionText="department"
          optionValue="department"
          validate={[required()]}
        />
        <AutocompleteInput
          choices={onetCodes}
          loading={isOnetLoading}
          source="onetCode"
          validate={[required()]}
          resettable={true}
          fullWidth
        />
        <Typography variant="body2" color="textSecondary">
          * Improve onet code search by using just one key word and then select
          an item from the list
        </Typography>
      </SimpleForm>
    </Create>
  );
};
