import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  required,
  AutocompleteInput,
} from "react-admin";
import { useDepartments } from "../../hooks/assessments.questions/useDepartments";
import { useOccupationsOnetCodes } from "../../hooks/occupations/useOccupationsOnetCodes";
import OccupationEditorToolbar from "./OccupationEditorToolbar";

export const OccupationEdit = (props) => {
  const departments = useDepartments();
  const { onetCodes, isOnetLoading } = useOccupationsOnetCodes();

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<OccupationEditorToolbar />}>
        <TextInput source="name" validate={[required()]} />
        <ReferenceArrayInput
          filter={{provider: `THRIVE`}}
          label="Recommended assessments"
          source="recommendedAssessmentIds"
          reference="assessments"
          perPage={100}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <SelectInput
          source="normType"
          defaultValue="STANDARD"
          choices={[
            { id: "VERY_EASY", name: "VERY_EASY" },
            { id: "EASY", name: "EASY" },
            { id: "STANDARD", name: "STANDARD" },
            { id: "HARD", name: "HARD" },
          ]}
        />
        <SelectInput
          source="department"
          choices={departments}
          optionText="department"
          optionValue="department"
          validate={[required()]}
        />
        <AutocompleteInput
          choices={onetCodes}
          loading={isOnetLoading}
          source="onetCode"
          validate={[required()]}
          resettable={true}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};
